<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">{{ title }}</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="姓名" prop="XM" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.XM"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="年龄" prop="NL" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.NL"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="传承人类型"
                    prop="LX"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.LX"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="特长" prop="TC" :style="{ width: '80%' }">
                    <el-input
                        type="textarea"
                        :rows="6"
                        v-model="formdata.dataDic.TC"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="传承人级别" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.JB"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    mixins: [infoMixin],
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            title: "",
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    XM: null, //姓名
                    NL: null, //年龄
                    LX: null, //类型
                    TC: null, //特长
                    JB: null, //级别
                },
                itemCode: "500203",
                metaJson: [],
            },
            fileList: [],
            rules: {
                XM: [
                    {
                        required: true,
                        message: "请填写姓名",
                        trigger: ["blur"],
                    },
                ],
            },
        };
    },
    watch: {},
    mounted() {
        this.title = this.$route.meta.title;
    },
    methods: {},
};
</script>

<style></style>
